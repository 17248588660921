import React from 'react';
import { css } from '@emotion/core'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import LogoHor from '../components/LogoHor';
import ContactForm from '../components/ContactForm';

export default (props) => {

    return (
        <div>
            <LogoHor origin={props.location.state ? props.location.state.prevPath : '/'} />
            <div css={css`
                min-height: 90vh;
                background-color: #fff;
                display: flex;
                flex-direction: column;
                padding: 2.5rem;
                padding-bottom: 8rem;
                @media only screen and (max-width: 26.5625em) {
                    height: unset;
                }
            `}>
                <picture css={css`
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                `}>
                    <img css={css`
                        width: 90%;
                        max-width: 45rem;
                    `} src="/images/maroma.png" alt="Maroma"/>
                </picture>
                <div css={css`
                    display: flex;
                    margin: 4.5rem auto 2.5rem;
                    width: 95%;
                    max-width: 120rem;
                    align-items: center;
                    @media only screen and (max-width: 26.5625em) {
                        flex-direction: column;
                    }
                `}>
                    <div css={css`
                        flex: 1;
                        font-size: 1.4rem;
                        width: 95%;
                        max-width: 75rem;
                        margin-left: auto;
                        @media only screen and (max-width: 26.5625em) {
                            margin: auto 0 0;
                            flex: unset;
                        }
                        p {
                            margin-bottom: 2rem;
                        }
                    `}>
                        <p>Maroma Galería en movimiento realiza exposiciones de arte en distintas locaciones de la CDMX las cuales han sido abandonadas, deshabitadas o no descubiertas.</p>
                        <p>En cada edición se presenta a un solo artista emergente y  el espacio es curado según los gustos y la obra del mismo, es decir, sus tragos favoritos en las barras, la playlist que suenan durante el evento, el diseño ambiental, etc. </p>
                        <p>Por otro lado, como es característico de Maroma, el artista siempre trabaja una pieza In situ, buscando que los asistentes puedan tener un aproximamiento a sus procesos de producción y puedan entender más su obra.</p>
                        <p>Además, en cada edición se invita a un artista musical también emergente buscando amenizar el espacio y tener distintos ambientes en el mismo evento.</p>
                    </div>
                    <div css={css`
                        flex: 1;
                        padding: 2rem;
                        @media only screen and (max-width: 26.5625em) {
                            margin-right: 0;
                            margin-bottom: 4.5rem;
                            flex: unset;
                        }
                    `}>
                        <Carousel showThumbs={false} showArrows={true}>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma-2/1.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma-2/2.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma-2/3.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma-2/4.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma-2/5.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma-2/6.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma-2/7.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma-2/8.jpg" />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
            <div css={css`
                padding: 4.5rem 2.5rem;
                background-color: black;
                width: 100%;
                display: flex;
            `}>
                <div css={css`
                width: 95%;
                max-width: 40rem;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                h3 {
                    font-size: 2rem;
                    color: #fff;
                    text-align: center;
                }
                form {
                    display: flex;
                    flex-direction: column;
                    .input-item {
                    display: flex;
                    flex-direction: column;
                    padding: 1rem 0;
                    font-size: 1.4rem;
                    color: #fff;
                    input, textarea {
                        margin-top: .5rem;
                        padding: 1rem;
                        background-color: transparent;
                        border: 1px solid #fff;
                        outline: none;
                        color: #fff;
                    }
                    }
                    button {
                    padding: 1rem;
                    background-color: #fff;
                    color: black;
                    text-transform: uppercase;
                    }
                }
                `}>
                    <h3>Conoce más de nuestros eventos</h3>
                    <ContactForm page="Maroma" />
                </div>
            </div>
        </div>
    )
}